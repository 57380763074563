<template>
  <ion-page>
    <ion-tabs
      @ionTabsWillChange="beforeTabChange"
      @ionTabsDidChange="afterTabChange"
    >
      <ion-tab-bar slot="bottom">
        <ion-tab-button tab="posts" href="/tabs/posts">
          <ion-icon :icon="icon.albumsOutline"></ion-icon>
          <ion-label>Posts</ion-label>
          <ion-badge v-if="newPostsCount !== ''">{{ newPostsCount }}</ion-badge>
        </ion-tab-button>

        <ion-tab-button tab="chats" href="/tabs/chats">
          <ion-icon :icon="icon.chatbubbleEllipsesOutline"></ion-icon>
          <ion-label>Chats</ion-label>
          <ion-badge v-if="newChatCount !== ''">{{ newChatCount }}</ion-badge>
        </ion-tab-button>

        <ion-tab-button tab="groups" href="/tabs/groups">
          <ion-icon :icon="icon.peopleOutline"></ion-icon>
          <ion-label>Groups</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="notifications" href="/tabs/notifications">
          <ion-icon :icon="icon.notificationsOutline"></ion-icon>
          <ion-label>Notifications</ion-label>
          <ion-badge v-if="newNotificationsCount !== ''">{{
            newNotificationsCount
          }}</ion-badge>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import { defineComponent } from "vue";
import {
  IonIcon,
  IonLabel,
  IonPage,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonBadge,
} from "@ionic/vue";
import {
  chatbubbleEllipsesOutline,
  albumsOutline,
  peopleOutline,
  notificationsOutline,
} from "ionicons/icons";

import { getNotifications } from "../services/apiCall.js";
import { formatDate, isAndroid, isIOS, logOffApp } from "../services/utils.js";

export default defineComponent({
  components: {
    IonIcon,
    IonLabel,
    IonPage,
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonBadge,
  },
  ionViewWillEnter() {
    console.log("TabsView - ionViewWillEnter");
    this.init();
  },
  ionViewWillLeave() {
    console.log("TabsView - ionViewWillLeave");
    clearInterval(this.notificationRefreshCallback);
  },
  setup() {
    const beforeTabChange = () => {
      // do something before tab change
    };
    const afterTabChange = () => {
      // do something after tab change
    };
    return {
      beforeTabChange,
      afterTabChange,
    };
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    newNotificationsCount() {
      let cnt = this.$store.getters.notificationCounts.new_count;
      if (typeof cnt === "undefined") return "";
      return cnt <= 10 ? (cnt === 0 ? "" : cnt) : "10+";
    },
    newChatCount() {
      let cnt = this.$store.getters.notificationCounts.new_chats;
      if (typeof cnt === "undefined") return "";
      return cnt <= 10 ? (cnt === 0 ? "" : cnt) : "10+";
    },
    newPostsCount() {
      return "";
    },
  },
  data() {
    return {
      icon: {
        chatbubbleEllipsesOutline,
        albumsOutline,
        peopleOutline,
        notificationsOutline,
      },

      notificationRefreshCallback: null,

      notificationsView: {
        notifications: [],
        new_count: 0,
        new_chats: 0,
      },
    };
  },
  methods: {
    async init() {
      // refresh notifications
      this.refreshNotifications();
      this.notificationRefreshCallback = setInterval(
        this.refreshNotifications,
        60000
      );
    },
    async refreshNotifications() {
      console.log("refreshNotifications [" + formatDate(new Date()) + "]");

      getNotifications({ limit: 10, skip: 0 })
        .then((res) => {
          if (res.data.status === 0) {
            this.notificationsView.notifications =
              res.data.result.notifications;
            this.notificationsView.new_count = res.data.result.new_count;
            this.notificationsView.new_chats = res.data.result.new_chats;

            this.$store.dispatch("setnotificationcounts", {
              new_count: this.notificationsView.new_count,
              new_chats: this.notificationsView.new_chats,
            });

            var sentNotifications = this.$store.getters.sentNotifications;

            this.notificationsView.notifications.map((notify) => {
              var alreadySent = sentNotifications.filter(
                (n) => n.id === notify.id
              );

              // console.log('alreadySent', alreadySent, sentNotifications);

              if (
                !notify.is_read &&
                alreadySent.length === 0 &&
                !isAndroid() &&
                !isIOS()
              ) {
                // console.log('save notify', notify);

                this.$store.dispatch("addnotification", notify);
              }

              return notify;
            });
          } else {
            console.log("getNotifications - err", res);

            // we have been logged out, kick the user out from here
            if (
              res.data.error.error_message === "Incorrect user tokens" ||
              res.data.error.error_message === "Invalid user session"
            ) {
              this.openToast(
                this.getStrings(this.strings, "LoggingOffUserInvalidSession")
              );
              this.logOff(this);
            }
          }
        })
        .catch((err) => {
          console.log("getNotifications - err", err, err.toString());
          if (err.toString().indexOf("ciphertext is undefined") >= 0) {
            this.openToast(
              this.getStrings(this.strings, "LoggingOffUserInvalidSession")
            );
            this.logOff(this);
          }
        });
    },
    async logOff(callbackObject = this) {
      callbackObject.logOutDisabled = true;

      if (callbackObject.notificationRefreshCallback !== null)
        clearInterval(callbackObject.notificationRefreshCallback);

      await logOffApp();

      callbackObject.$store.dispatch("adduser", {});

      callbackObject.router.replace("/login");
    },
  },
});
</script>